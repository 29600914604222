.filter {
  &__menu {
    margin: 0.125rem auto;
    color: #333333 !important;
  }

  &__option {
    background-color: white !important;
    color: #333333 !important;

    &--is-focused {
      background-color: #2684ff !important;
      color: white !important;
    }
  }

  &__group {
    padding: 0;
  }

}


.react-select-container {

  .react-select__option {
    background-color: $light-bg-subtle !important;
    color: #333333 !important;

    &--is-focused {
      background-color: #2684ff !important;
      color: white !important;
    }
  }

}

[data-bs-theme="dark"]{
  .react-select-container {

    .react-select__control {
      background-color: transparent !important;
    }
    
    .react-select__single-value{
      color: white !important;
    }
   
    .react-select__menu {
      background-color: #333333 !important;
    }

    .react-select__option {
      background-color: #333333 !important;
      color: white !important;
  
      &--is-focused {
        background-color: #6da5ef !important;
        color: white !important;
      }
    }
  }
}
