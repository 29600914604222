.custom-top-bar{
    background-color: var(--bs-topnav-bg);
}
  
.dropdown-custom{
    background-color:#ffffff;
}

[data-bs-theme="dark"]{
    .dropdown-custom{
        background-color:#2D3640;
    }
}