.video-introduce{
    box-sizing: border-box;
    padding: 8px;
    iframe{
        width: 98%;
        height: auto;
        min-width: 240px;
        aspect-ratio: 16 / 9;
        border-radius: 20px;
    }
}