#spinner-container{
    position: fixed;
    inset: 0;
    visibility: visible;
    transition: all 0.5s ease;
    opacity: 1;
    z-index: 999;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    background-color: rgba(229,247,241, 0.5);

    #spinner-box{
        display: flex;
        justify-content: center;
        z-index: 9999;
    }

    .spinner-item {
        z-index: 99999;
    }
}