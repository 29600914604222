.aboutus {
    .introduce {
        position: relative;
        width: 100%;
        height: 700px;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .introduce-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
    }

    .introduce-text {
        font-family: Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        position: absolute;
        top: 42.5%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #ffffff;
        text-align: center;
        z-index: 99;
        font-size: 24px !important;
        line-height: 48px !important;
        padding: 0 10px;
        animation: jumpInText 1s ease-out;
    }

    .introduce-icon {
        animation: jumpInIcon 1s ease-out;
    }

    .employee {
        padding: 32px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 480px;
        }

        .employee-text {
            margin-right: 24px;
        }
    }

    .company {
        padding: 32px 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 480px
        }

        .company-text {
            margin-left: 24px;
        }
    }


    @media (max-width: 768px) {
        .introduce-text {
            font-size: 16px !important;
            line-height: 32px !important;
        }

        .video-embed {
            margin: 4px !important;

            iframe {
                width: 100%;
            }
        }

        .employee {
            flex-wrap: wrap-reverse;

            img {
                width: 100%;
            }

            .employee-text {
                margin: 0;
            }
        }

        .company {
            flex-wrap: wrap;

            img {
                width: 100%;
            }

            .company-text {
                margin: 0;

            }
        }

    }

    @media (max-width: 1024px) {
        .introduce-text {
            font-size: 20px !important;
            line-height: 40px !important;
        }
    }

    @keyframes jumpInText {
        0% {
            transform: translate(-50%, -25%);
            opacity: 0;
        }

        100% {
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }

    @keyframes jumpInIcon {
        0% {
            transform: translateY(-50%);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

}