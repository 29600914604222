//
//form.scss
//

.form-control,
.form-select {
  padding: 10px;
  font-size: 14px;
  border-color: var(--#{$prefix}border-color);
  font-weight: 500;

  &::-webkit-input-placeholder {
    color: $gray-500;
    font-size: 13px;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $gray-500;
    font-size: 13px;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $gray-500;
    font-size: 13px;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $gray-500;
    font-size: 13px;
  }
}

[type="search"]::-webkit-search-cancel-button,
.form-check-label,
.form-check-input {
  cursor: pointer;
}

.uploadfile {
  position: relative;
  width: 242.86px;
  height: 242.86px;
  border: 1px dashed #aaa;
  text-align: center;
}

.uploadfile__input {
  position: absolute;
  top: 112px;
  left: 0;
  visibility: hidden;
  opacity: 0;
}

.uploadfile__label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  //z-index: 2;
  background-color: transparent;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.uploadfile__label::after {
  content: '+';
  color: #02af74;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 160px;
  font-weight: 400;
  cursor: pointer;
}

.uploadfiled__label::after {
  content: '+';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 160px;
  font-weight: 400;
  cursor: pointer;
  visibility: hidden;
}

.uploadfile__label.draging {
  background-color: #c8dadf;
}

.uploadfile__image {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 70%;
  max-height: 70%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.uploadfiled__image {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 70%;
  max-height: 70%;
  transform: translate(-50%, -50%);
  z-index: 1;
  visibility: hidden;
}

.uploadfile__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  color: #797979;
  cursor: pointer;
  z-index: 4;
}

.break-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.about-company {
  display: block;
  display: -webkit-box;
  font-size: 16px;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-homepage {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pt-custom {
  position: absolute;
  top: 92%;
  left: 50%;
  max-width: 80%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
}